<template>
  <v-card 
    class="travel-card text-center position-relative"
  >    
    <div class="card-header text-nowrap font-weight-bold d-flex align-items-center justify-content-center p-2 w-100"
      :class="headerBgColor" 
    >
      <img :src="logo" alt="supplier logo" class="mx-5">
      <p class="mb-0 mx-3">
        {{ supplier }}
      </p>
    </div>
    <div v-if="isXbox" class="text-center">
      <p class="font-weight-bold mt-3 mb-0">Xbox Gold</p>
      <div class="d-flex align-items-center">
        <v-icon class="mx-5 grey--text text--lighten-1">mdi-calendar-month</v-icon>
        <span class="fs14">{{ $t("Valid") }}: 6 months</span>
      </div>
    </div>
    <div v-else class="d-flex flex-wrap pt-2">
      <div class="w-100 fs14 font-weight-bold" style="height: 42px;" v-if="type==4 || type==6">
        {{ name }}
      </div>
      <v-tooltip bottom class="mx-2" v-if="calls">
        <template v-slot:activator="{ on, attrs }">
          <div class="w-50 d-flex align-items-center my-1" v-bind="attrs" v-on="on">
            <v-icon class="mx-3 grey--text text--lighten-1" small>mdi-phone</v-icon>
            <span class="fs14">
              {{ calls || '---' }}
            </span>
          </div>
        </template>
        <span>{{ $t("Call Minutes") }}</span>
      </v-tooltip>
      <v-tooltip bottom class="mx-2" v-if="sms">
        <template v-slot:activator="{ on, attrs }">
          <div class="w-50 d-flex align-items-center my-1" v-bind="attrs" v-on="on">
            <v-icon class="mx-3 grey--text text--lighten-1" small>mdi-message-processing-outline</v-icon>
            <span class="fs14">
              {{ sms || '---' }}
            </span>
          </div>
        </template>
        <span>{{ $t("SMS Messages") }}</span>
      </v-tooltip>
      <v-tooltip bottom class="mx-2" v-if="network">
        <template v-slot:activator="{ on, attrs }">
          <div class="w-50 d-flex align-items-center my-1" v-bind="attrs" v-on="on">
            <v-icon class="mx-3 grey--text text--lighten-1" small>mdi-wifi</v-icon>
            <span class="fs14">
              {{ network || '---' }}
            </span>
          </div>
        </template>
        <span>{{ $t("Mobile Data") }}</span>
      </v-tooltip>
      <v-tooltip bottom class="mx-2" v-if="days">
        <template v-slot:activator="{ on, attrs }">
          <div class="w-50 d-flex align-items-center my-1" v-bind="attrs" v-on="on">
            <v-icon class="mx-3 grey--text text--lighten-1" small>mdi-calendar-month</v-icon>
            <span class="fs14">
              {{ days || '---' }}
            </span>
          </div>
        </template>
        <span>{{ $t("Validation days") }}</span>
      </v-tooltip>
    </div>
    <div class="d-flex justify-content-between align-items-center px-5 card-action_block">
      <div>
        <v-icon 
          v-if="show_additional_details"
          color="primary"
          class="mx-2"
          @click="openInforPopup()"
        >
          mdi-information
        </v-icon>
      </div>
      <div class="text-center font-weight-bold">
        <p class="mb-0 fs21"><span class="fs16">₪</span>{{ price }}</p>
        <router-link :to="{ name: product_router, params: { id: id, supplier_id: supplier_id } }" tag="a" exact>
          <v-btn
            tile
            color="primary"
            small
            class="text-none"
          >
            <v-icon left class="white--text">
              mdi-cart
            </v-icon>
            {{ $t("Buy") }}
          </v-btn>
        </router-link>
      </div>
    </div>
    <InforPopup :inforData="additional_details" :modal_key="modal_key"></InforPopup>
  </v-card>
</template>

<script>
import InforPopup from '@/components/communication/InforPopup'

export default {
  name: "SupplierDealCard",
  components: {
    InforPopup
  },
  props: {
    headerBgColor: String,
    logo: String, 
    supplier: String, 
    calls: Number,
    sms: Number,
    network: [Number, String],
    days: Number,
    price: Number,
    isXbox: {
      type: Boolean,
      default: false
    },
    id: Number,
    supplier_id: Number,
    product_router: String,
    name: String,
    type: [Number, String],
    show_additional_details: Boolean,
    additional_details: String
  },
  data() {
    return {
      modal_key: +new Date()
    }
  },
  methods: {
    openInforPopup() {
      this.$modal.show("inforPopup_"+this.modal_key);
    }, 
  }
}
</script>

<style lang="scss" scoped>
  .travel-card {
    width: 177px;
    height: 202px;
    margin: 10px;
    @media screen and (max-width: 480px)  {
      width: calc(50% - 10px);
      margin: 5px;
    }
    .card-header {
      height: 40px;
      img {
        height: 100%;
        max-width: 50%;
        object-fit: contain;
      }
    }
    .card-action_block {
      position: absolute;
      width: 100%;
      bottom: 5px;
    }
  }
</style>